<template>
  <div class="M-content">
    <!-- headerbar部分 -->
    <div class="img">
      <img src="../../assets/backImg.jpg" alt="" />
      <div class="title">
        <commonT :value="titleConent" class="commonT" />
      </div>
    </div>
    <!-- 团队内容 -->
    <div class="Teamlist" v-if="teamShow">
      <div class="TeamItem" v-for="(item, index) in teamList" :key="index">
        <img
          :src="
            item.teamPersonalImg && item.teamPersonalImg.length > 0
              ? item.teamPersonalImg[0].url
              : ''
          "
          alt=""
        />
        <div class="teamTitle">
          <p>{{ item.teamPersonalName }}</p>
          <p>{{ item.teamPersonalIntroduction }}</p>
        </div>
        <div class="arrow" @click="navTodetail(item,index)">
          查看详情<van-icon name="arrow" class="my-icon" />
        </div>
      </div>
      <div class="pagination">
        <van-pagination
          v-model="pageIndex"
          :total-items="total"
          :items-per-page="pageSize"
          @change="changeIndex"
          :show-page-size="3"
          force-ellipses
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
        </van-pagination>
      </div>
    </div>
    <!-- 详情页  -->
    <div class="team_details" v-else>
      <!-- 点击按钮 -->
      <div class="arrowLeft">
           <van-icon name="arrow-left"  @click="teamShow=true" size="20px" />
      </div>
      <div class="changeteamItem">
        <van-button  :disabled="pre"  @click="handlePre">上一位</van-button >
        <van-button  :disabled="next" @click="handleNext">下一位</van-button >
      </div>
      <div class="team_list">
        <div class="Itemdetail">
          <img
            :src="
              Itemlist.teamPersonalImg && Itemlist.teamPersonalImg.length > 0
                ? Itemlist.teamPersonalImg[0].url
                : ''
            "
            alt=""
          />
          <div class="Selfcontent">
            <p>{{ Itemlist.teamPersonalName }}</p>
            <!-- <p>{{ Itemlist.teamPersonalIntroduction }}</p> -->
          </div>
        </div>
        <div class="description">
            <p>{{ Itemlist.teamPersonalIntroduction }}</p>
        </div>
        <div class="richcontent">
          <!-- <div class="detals">详细信息</div> -->
            <van-divider content-position="left">详细信息</van-divider>
          <div class="showrich" v-html="Itemlist.teamPersonalDetailed"></div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- 尾部部分 -->
    <FooterContainer />
  </div>
</template>
<script>
export default {
  name: "M-companyTeam",
  data() {
    return {
      titleConent: {
        name: "公司团队",
        ename: "Team",
      },
      pageIndex: 1,
      pageSize: 4,
      total: 0,
      teamList: [],
      teamShow: true,
      Itemlist:{},
       pre:false,
      next:false,
      indexswitch:0
      //   currentPage: 1,
    };
  },
  created() {
    this.getTeamInit();
    if(this.$route.query.data&&this.$route.query.index){
        let data=JSON.parse(this.$route.query.data),
            index=this.$route.query.index
            this.navTodetail(data,index)
    }
  },
  methods: {
    //初始化获得团队数据
    async getTeamInit() {
      let result = await this.$api.getCompanyTeam(
        this.pageIndex,
        this.pageSize
      );
      this.total = Number(result.page.totalCount);
      console.log(this.total);
      let res = result.data;
      console.log(res);
      if (res.length > 0) {
        res.forEach(
          (item) => (item.teamPersonalImg = JSON.parse(item.teamPersonalImg))
        );
      }

      this.teamList = res;
    },
    //切换分页索引值
    changeIndex(i) {
      this.pageIndex = i;
      this.getTeamInit();
    },
    //
    navTodetail(data,i) {
      console.log(data,i);
        i==0?this.pre=true: this.pre=false
      
     
      console.log(i,this.teamList.length-1)
      this.next=i==this.teamList.length-1
    //   this.next=false
      this.indexswitch=i
      this.teamShow = false;
      this.Itemlist = data;
    },
    //点击切换上一位
    handlePre(){
       this.indexswitch--
       if(this.indexswitch!==this.teamList.length-1) this.next=false
       if(this.indexswitch==0){
         this.Itemlist=this.teamList[0]
         this.pre=true
         return
      }
      if(this.indexswitch<0) return
        this.Itemlist=this.teamList[this.indexswitch]
         
    },
    //点击切换下一位
    handleNext(){
       this.indexswitch++
       if(this.indexswitch!==0) this.pre=false
        if(this.indexswitch==this.teamList.length-1){
          this.Itemlist=this.teamList[this.teamList.length-1]
           this.next=true
           return 
        }
        if(this.indexswitch>this.teamList.length-1)return
           this.Itemlist=this.teamList[this.indexswitch]
           
    }
  },
};
</script>
<style lang="scss" scoped>
.M-content {
  height: 100%;
  .img {
    width: 100%;
    height: 165px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .title {
      width: 80%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 64px;
      box-sizing: border-box;
      background-color: #fff;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: #ddd;
      }

      .commonT {
        width: 100%;
        padding-left: 20px;
      }
    }
  }
  //团队内容区
  .Teamlist {
    width: 80%;
    min-height: 600px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 30px;
    .TeamItem {
      height: 190px;
      padding: 20px 10px;
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 20px;
      // border-radius: 20px;
      background-color: rgb(246, 246, 246);
      .arrow {
        position: absolute;
        right: 10px;
        bottom: 2px;
        font-size: 10px;
        display: flex;
        align-items: center;
        border: 1px solid #eee;
        padding: 4px 10px;
        border-radius: 10px;
        background-color: #fff;
        color: #777;
        &:hover {
          background-color: pink;
        }
        .my-icon {
          margin-left: 5px;
        }
      }
      img {
        width: 100px;
        // border-radius: 50%;
        height: 150px;
      }
      .teamTitle {
        margin-left: 10px;
        flex: 1;
        p {
          text-align: left;
          &:first-of-type {
            font-size: 18px;
          }
          &:last-of-type {
            font-size: 13px;
            color: rgb(190, 186, 196);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6; /*3表示只显示3行*/
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .pagination {
      margin: 20px 0;
    }
  }
}
//团队详情样式
.team_details {
  position: relative;
  display: flex;
  width: 90%;
  min-height:500px;
  margin: 0 auto;
  .arrowLeft{
      position: absolute;
      top:20px;
      left:20px;

  }
  .changeteamItem {
    margin-top: 100px;
    width: 20%;
    display: flex;
    flex-direction: column;
    .van-button  {
      width: 50%;
      height:70px;
      margin: 30px auto;
      border-radius:20px;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    }
  }
  .team_list {
    flex: 1;
    padding: 40px 20px;
    .Itemdetail {
         display: flex;
      img {
        width: 100px;
        height: 150px;
      }
      .Selfcontent{
         flex:1;
         p{
           text-align: left;
           padding-left:10px;
           &:first-of-type{
             color:rgba(0,0,0,0.7);
             font-size:18px;
           }
        //    &:last-of-type{
        //      font-size: 13px;
        //     color: rgb(147, 136, 163);
        //     line-height:1.8em;
        //    }
         };
      }
     
    }
     .description{
         min-height:100px;
          p{
              text-indent: 20px;
              text-align: left !important;
              color:#666;
          }
         
      }
  }
  .richcontent{
      .detals{
         height:60px;
         line-height:60px;
         text-align:left;
         font-size:17px;
         border-bottom:1px solid #eee;
      }
      
       .showrich /deep/ p {
        word-break: break-all;  //超出宽度截取
        max-width: 100% !important;
        font-size:15px;
      }
      .showrich /deep/ img {
        display: block;
        margin: 0 auto;
        max-width: 100% !important;
      }
  }
}
</style>